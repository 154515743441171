<template lang="">
  <div class="container">
    <SubHeader :pathList="pathList" :title="title" />

    <div id="contents" class="contents">
      <div class="title-sort-box">
        <h3>{{ $t('qna.detail-title') }}</h3>
      </div>
      <!--/title-sort-box-->
      <article class="board-view">
        <div class="view-head">
          <div class="view-head-left">
            <h4>{{ detailData.questSj }}</h4>
          </div>
          <div class="view-head-right">
            <dl>
              <dd>
                <span>{{ $t('qna.detail-label-name') }}</span
                ><b>{{ common_getMaskingName(detailData.qstnerNm) || '-' }}</b>
              </dd>
              <dd>
                <span>{{ $t('qna.detail-label-email') }}</span
                ><b>{{
                  common_getMaskingEmail(detailData.qstnerEmail) || '-'
                }}</b>
              </dd>
              <dd>
                <span>{{ $t('qna.detail-label-tel') }}</span
                ><b>{{
                  common_getMaskingPhoneNo(
                    common_getTelephoneNoText(detailData.qstnerTlnum),
                  ) || '-'
                }}</b>
              </dd>
            </dl>
          </div>
        </div>
        <div
          class="view-body"
          v-html="replaceCn(detailData.questCnEscape)"
        ></div>
        <div
          class="view-body-reply"
          :style="detailData.rspnsCn ? '' : 'display:none'"
        >
          <dl>
            <dt>{{ $t('qna.answered-flag') }}</dt>
            <dd v-html="replaceCn(detailData.rspnsCn)"></dd>
          </dl>
        </div>
      </article>

      <div class="btns-bottom">
        <button type="button" class="bt-md bt-default" @click="goBack">
          <span>{{ $t('common.btn-back') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { fetchQnaDetail } from '@/api/qnaApi.js';
import CommonMixins from '@/mixins/CommonMixins.js';
export default {
  mixins: [CommonMixins],
  data() {
    return {
      pathList: [
        this.$i18n.t('menu.home'),
        this.$i18n.t('menu.support'),
        this.$i18n.t('subMenu.qna'),
      ],
      title: {
        mainTitle: this.$i18n.t('menu.support'),
        smallTitle: this.$i18n.t('menu.support-eng'),
      },
      detailData: {},
    };
  },
  created() {
    this.fetchQnaDetail();
  },
  methods: {
    // 문의 상세 정보 조회
    async fetchQnaDetail() {
      this.$dialogs.loading();
      const { questNo } = this.$route.params;
      const {
        data: { detail },
      } = await fetchQnaDetail(questNo).catch(() => {
        this.$dialogs.close();
      });
      this.detailData = detail;
      this.$dialogs.close();
    },
    // 목록으로
    goBack() {
      const { page } = this.$route.params;
      this.$router.push({ name: 'qna', params: { page } });
    },
  },
};
</script>
<style lang=""></style>

import { qna } from './index';

/**
 * QNA 리스트 조회
 */
function fetchQnaList(resObj) {
  return qna.post(`/list`, resObj);
}

/**
 * QNA 상세 조회
 */
function fetchQnaDetail(questNo) {
  return qna.post(`/detail`, { questNo });
}

function insertQna(params) {
  return qna.post(`/insert`, params);
}

function checkPassword(params) {
  return qna.post(`/password_check`, params);
}

export { fetchQnaList, fetchQnaDetail, checkPassword, insertQna };
